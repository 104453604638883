import React  from "react";
import {
  StyledTechnological,
  StyledTechnologicalIntro,
  StyledTechnologicalTitle,
  StyledTechnologicalSubtitle,
  StyledTechnologicalItems,
  StyledTechnologicalItem,
  StyledTechnologicalItemImage,
  StyledTechnologicalItemContent,
  StyledTechnologicalItemCounter,
  StyledTechnologicalItemTitle,
  StyledTechnologicalCTA,
} from "./style.jsx";
import Button from "../Button/Button";
import Icon, { icons } from "../Icon/Icon";
import { StyledGridRow } from "../../commons/Grid";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default ({
  title, subtitle, items, cta, allImages, 
}) => {
  return (
    <StyledTechnological>
      <StyledGridRow>
        <StyledTechnologicalIntro>
          <StyledTechnologicalTitle
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <StyledTechnologicalSubtitle
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        </StyledTechnologicalIntro>
        <StyledTechnologicalItems>
          {items.map((item, index) => {
            const imageData = allImages.find(e => e.path === item.image);

            return (
              <StyledTechnologicalItem key={index}>
                <StyledTechnologicalItemImage>
                  {imageData && <GatsbyImage image={getImage(imageData)} alt={imageData.alt} />}
                </StyledTechnologicalItemImage>
                <StyledTechnologicalItemContent>
                  <StyledTechnologicalItemCounter>
                    0{index + 1}
                  </StyledTechnologicalItemCounter>
                  <StyledTechnologicalItemTitle>
                    {item.title}
                  </StyledTechnologicalItemTitle>
                </StyledTechnologicalItemContent>
              </StyledTechnologicalItem>
            );},
          )}
        </StyledTechnologicalItems>
      </StyledGridRow>
      <StyledTechnologicalCTA>
        <Button as="div" to={cta.url}>
          {cta.label} <Icon icon={icons.arrowRight} />
        </Button>
      </StyledTechnologicalCTA>
    </StyledTechnological>
  );
};
